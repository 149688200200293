.product_favorite {
  padding: 24px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background: var(--Backgroud-Backgroud-1, #fff);
  min-height: 100vh;
  border-left: 1px solid var(--Stoke-Stoke, #dbdfe5);

  &__title {
    color: var(--Text-colorcolor-Text-primaty, #192434);
    /* Heading 6/B */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
  }
  &__content {
    margin-top: 24px;

    &__left {
      display: flex;
      flex-direction: column;
      text-align: center;

      &__image {
        margin-bottom: 24px;
        img {
          width: 160px;
          height: 160px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      &__btn {
        button {
          margin-bottom: 12px;
        }
        span {
          display: block;
          color: var(--Text-colorcolor-Text-secondary, #626d7c);
          text-align: center;
          /* Body/R */
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }

    &__right {
      form {
        radio:checked {
          color: #00904a;
        }

        button {
          border-radius: 4px;
          background: var(--Backgroud-Backgroud-button, #e8ebef);

          &:hover {
            background: var(--primary-basic-10, #00904a);
          }
        }
      }
    }
  }
}
